import 'bootstrap/dist/css/bootstrap.min.css';
import React  from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import home from './css/home.module.css';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
function Header() {

  return (
    <>
    
    <Navbar bg="light" expand="lg" className={`shadow-lg animate__animated animate__slideInDown ${home.navBar_top}`}>
      <Container fluid>
      <div className={`logo  animate__slideInDown ${home.navBar_top}`}><img className={home.logoDiv} src={logo} alt=""/></div>
        <Navbar.Brand to="/">
         
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" >
          <Nav className={home.navBar}>
            <Link to="/">Home</Link>
            <Link to="/about-us">Who We Are</Link>
            <Link to="/">Our Approach</Link>
            <Link to="/Contactus">Contact Us</Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success"><SearchIcon/></Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    
    </>
  );
}




export default Header;
