import React from 'react';
import { FcCellPhone } from "react-icons/fc";
import {BiCurrentLocation} from "react-icons/bi"
function Footer() {

  return (
    <>
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 text-light">
                          <h5>Contact Us</h5>
                            <p><b>The Triumph Junior</b><br/>
                                 <BiCurrentLocation/> B-153 Saipuram<br/>
                                  Behind Shatbdipuram<br/>
                                  Near Govindpuram Bus stand<br/>
                                  201013 <br/>
                                  <FcCellPhone/> +91 8700530184<br/>
                            </p>
                    </div>
                    <div className="col-md-3 text-light">
                          <h5>Careers</h5>
                            <p className="svgset">
                            Join us in teaching the next<br/>
                            generation! At the Triumph Junior,<br/>
                            we are always looking for<br/>
                            great leaders to join our team!<br/>
                            <b>View Current Openings</b>
                            </p>
                    </div>
                    <div className="col-md-3 text-light">
                          <h5>Quick Links</h5>
                            <p>Contact Us <br/>
                                Privacy & Legal <br/>
                                Blog <br/>
                                CollegeBound™ Scholars Program <br/>
                                Staff Login <br/>
                                Parent Portal Help Page <br/>
                                Accessibility</p>
                    </div>
                    <div className="col-md-3 text-light">
                          <h5>Join Our Community</h5>
                            <button className="btnn">Connect With Us</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}




export default Footer;
