import './App.css';
import React  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route } from "react-router-dom";
import Home from './componets/Home';
import Header from './componets/coman/Header';
import Footer from './componets/coman/Footer';
import About from './componets/About';
import Contact from './componets/Contactus';
import 'animate.css';

function App() {
  return (
    <>
    <Header/>
    <Routes>        
        <Route index element={<Home />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/Contactus' element={<Contact />} />       
    </Routes>
    <Footer/>

    </>
  );
}

export default App;
