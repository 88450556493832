import Banner from './images/about.png';
import pic1 from './images/about-us/person.jfif';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BlurOnIcon from '@mui/icons-material/BlurOn';
function About() {
  return (
    <>
      <div className='aboutBanner'>
        <img src={Banner} alt="" />
      </div>
      <div className='container'>
    <div className='teamSection'>
        <div className='meetTeam'>About us</div>
        <p className='runText'>We are the part of The Triump Academy. We mentor the junior champions with high care and cultive the exellence in them from day one. 
        <br/>We are one of leading educational institute which make education possible for all kids around community.</p>
        <div className='forStyles'>
          <BlurOnIcon className='styles' />
          <BlurOnIcon className='styles' />
          <BlurOnIcon className='styles' />

        </div>
        <p className='aboutText'>
           
        </p>


      </div>
    </div>
      <div className='teamSection forCls'>
        <div className='meetTeam'>Meet our Team</div>
        <p className='runText'>Highly experienced, well qualified teachers and coaches</p>
        <div className='forStyles'>
          <BlurOnIcon className='styles' />
          <BlurOnIcon className='styles' />
          <BlurOnIcon className='styles' />

        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="our_team">
                <div className="pic">
                  <p className="description"></p>
                  <img src={pic1} alt="Principal" />
                </div>
                <h3 className="title">Sanjay Singh</h3>
                <span className="post">Principal & Admin Head</span>
                <ul className="social">
                  <li><a href="#" className="fab fa-facebook"><FacebookIcon /> </a></li>
                  <li><a href="#" className="fab fa-twitter"><TwitterIcon /></a></li>
                  <li><a href="#" className="fab fa-google-plus"><AddToDriveIcon /></a></li>
                  <li><a href="#" className="fab fa-linkedin"><LinkedInIcon /></a></li>
                </ul>
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className="our_team">
                <div className="pic">
                  <p className="description"></p>
                  <img src={pic1} alt="" />
                </div>
                <h3 className="title">Ajay Singh</h3>
                <span className="post">Co-Ordinator</span>
                <ul className="social">
                  <li><a href="#" className="fab fa-facebook"><FacebookIcon /> </a></li>
                  <li><a href="#" className="fab fa-twitter"><TwitterIcon /></a></li>
                  <li><a href="#" className="fab fa-google-plus"><AddToDriveIcon /></a></li>
                  <li><a href="#" className="fab fa-linkedin"><LinkedInIcon /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>


  
    </>
  );
}

export default About;
