import React  from 'react';
import home from './../coman/css/home.module.css';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import imag1 from '../images/1.jpg';
import ima51 from '../images/5.jpg';
import grow from '../images/grow-careers.svg';
import book from '../images/nha-book.svg';
import enrollementform from '../doc/enrollment.docx';




function MoreData() {
  const onButtonClick = () => {
    // using Java Script method to get docy file
    fetch(enrollementform).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = enrollementform;
            alink.click();
        })
    })
  }
   
  return (
    <>
      <div className={home.selectBar}>
        <Container>
          <Row>
            <Col className='col-sm-6 col-12'>
              <div className={home.findSchool}> </div>
            </Col>            
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col className='col-md-7 col-sm-7 col-12 animate__animated animate__lightSpeedInLeft'>
            <p className={home.heading}><FormatQuoteIcon /> Now Taking Applications</p>
            <p className={home.runningText}>At The Triumph Junior, preparing kids for competitive world starts on day one.
              We set high standards, make expectations clear, personalize teaching, and as a result,
              our students thrive.
            </p>
            <p className={`mt-3 ${home.runningText}`}>
              Be part of a school community that's safe, challenging, and committed to your child's success.
            </p>
            <button className='btn btn-primary mt-2' onClick={onButtonClick}>Download Enrollment Form 2023 - 2024</button>
          </Col>
          
        </Row>
      </Container>
      <div className={home.grayImgs}>
        <Container fluid>
          <Row>
            <Col className='col-md-6 col-sm-12 col-12'>
              <div className={home.imagesSections}>
                <img src={imag1} alt="" />
              </div>
            </Col>
            <Col className='col-md-6 col-sm-12 col-12'>
              <div className={home.textSec}>
                <p className={home.heading}><FormatQuoteIcon /> Our Approach</p>
                <p className={home.runningText}>Success means creating endless opportunities for our students to become their best.
                  Our goal is to help them build the knowledge, skills, and character they need to excel in school and in life.
                  Our college-prep program and moral focus curriculum help your child reach their full potential.
                </p>

                <button className='btn btn-primary mt-5'>More on Our Approach</button>
              </div>
            </Col>
          </Row>
        </Container>


      </div>
      <Container>
        <Row>
          <Col className='col-md-7 col-sm-7 col-12 animate__animated animate__lightSpeedInLeft'>
            <p className={home.heading}><FormatQuoteIcon /> Who We Are</p>
            <p className={home.runningText}>We are the part of The Triump Academy. We mentor the junior champions with high care and cultive the exellence in them from day one.
            </p>

            <button className='btn btn-primary mt-2'>Who We Are</button>
          </Col>
          <Col className='col-md-5 col-sm-5 col-12 animate__animated animate__lightSpeedInRight'>
            <div className={home.map_box}>
              <img src={book} alt="" />
            </div>
          </Col>
        </Row>
      </Container>

      <div className={home.blueSections}>
        <div className={home.innerSection}>
          <Container>
            <Row>
              <div className={home.innerTextbox}>
                <p className={home.headingText}>What is a the Triumph Academy?</p>
                <p className={home.runTextWhite}>
                The Triumph Academy is  one stop solution for your all competitive examinations prepration and tutions.
                We mentor the students presonally by keeping quality and excellence in mind.
                </p>
                <button className='btn btn-light'>What is a The Triumph Academy?</button>
              </div>
            </Row>
          </Container>
        </div>
      </div>

      <div className={home.ourSchools}>
        <Container>
          <p className={home.heading}><FormatQuoteIcon /> Our Schools, Our Stories</p>
          <p className={home.runningText}>One of the things that makes our charter schools so unique
            is the custom school experience created by each local staff. Learn more about our amazing
            school communities.
          </p>
          <Row className='mt-5'>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className={home.serviceBox}>
                  <img src={ima51} alt="" />
                  <h3 className={home.title}>Focus on Educations, </h3>
                  <p className={home.description}> Sharing, and Life Lessons Featured in our Favorite Stories<br /></p>
                  <div className={home.service_icon}>

                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className={home.serviceBox}>
                  <img src={ima51} alt="" />
                  <h3 className={home.title}>Focus on Safety, </h3>
                  <p className={home.description}> Sharing, and Life Lessons Featured in our Favorite Stories<br /></p>
                    
                  <div className={home.service_icon}>

                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className={home.serviceBox}>
                  <img src={ima51} alt="" />
                  <h3 className={home.title}>Focus on Hobbies, </h3>
                  <p className={home.description}> Sharing, and Life Lessons Featured in our Favorite Stories<br /></p>
                  <div className={home.service_icon}>

                  </div>
                </div>
              </div>

            </div>



          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col className='col-md-7 col-sm-7 col-12 animate__animated animate__lightSpeedInLeft'>
            <p className={home.heading}><FormatQuoteIcon /> Grow With Us</p>
            <p className={home.runningText}>Everything we do comes from a passion to provide our students the best
              opportunities for future success. This means we hold ourselves to a high standard of excellence
              and are always looking for individuals who share this passion. If you have a desire to teach eager minds and shape future leaders, we invite you to look at our open positions.
            </p>

            <button className='btn btn-primary mt-2'>Career Opportunities</button>
          </Col>
          <Col className='col-md-5 col-sm-5 col-12 animate__animated animate__lightSpeedInRight'>
            <div className={home.map_box}>
              <img src={grow} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MoreData;
