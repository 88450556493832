import '../App.css';
import contactbaner from './images/contactUsBanner.e35e7253.webp';
import ssss from './images/form.fd27069f.webp';
import {BiCurrentLocation} from "react-icons/bi"


function Contact() {
const onSubmitClick = () => {
    alert("Sending Email...");
    let name = document.getElementById("fullname").value;
    let emailId = document.getElementById("emailId").value;
    let mobileNo = document.getElementById("mobileNo").value;
    let message = document.getElementById("message").value;
    alert(name +", "+ emailId +","+ mobileNo +","+message);
}

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <img src={contactbaner} alt="" className='w-100 mb-2' />
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 rotatedivbb'>
                        <div className='rotatediv'>
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium contact_iconInbox__2PqGj css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="LocationOnIcon"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path></svg>

                        </div>
                        <h3>Address</h3>
                        <p><b>The Triumph Junior</b><br/>
                                 <BiCurrentLocation/> B-153 Saipuram<br/>
                                  Behind Shatbdipuram<br/>
                                  Near Govindpuram Bus stand<br/>
                                  201013 <br/>
                            </p>

                    </div>
                    <div className='col-md-4 rotatedivbb'>
                        <div className='rotatediv'>
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium contact_iconInbox__2PqGj css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="LocalPhoneIcon"><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg>
                        </div>
                        <h3>Contact Number</h3>
                        <p>+91 8700530184<br/></p>
                    </div>
                    <div className='col-md-4 rotatedivbb'>
                        <div className='rotatediv'>
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium contact_iconInbox__2PqGj css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="EmailIcon"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"></path></svg>
                        </div>
                        <h3>Email Address</h3>
                        <p>info@triumph.net</p>
                    </div>


                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 headinggs'>
                        <h1>SEND US A MESSAGE</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <img src={ssss} alt="" className='w-100 mb-5' />
                    </div>
                    <div className='col-md-6 mb-5 liner'>
                        <label>Full Name :</label><br />
                        <input type="text" className='formmm' required id="fullname" /><br />
                        <label>Email address :</label><br />
                        <input type="text" className='formmm' required id="emailId" /><br />
                        <label>Mobile number :</label><br />
                        <input type="text" className='formmm' required id="mobileno" /><br />
                        <label>Query :</label><br />
                        <textarea rows="4" cols="50" required id="message"></textarea><br />
                        <button className='btn btn-primary' onClick={onSubmitClick}>Submit</button>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Contact;
