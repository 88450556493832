import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../images/banner-img/1.jpg';
import slide2 from '../images/banner-img/2.jpg';
import slide3 from '../images/banner-img/3.jpg';
import slide4 from '../images/banner-img/4.jpg';
import slide5 from '../images/banner-img/5.jpg';
import slide6 from '../images/banner-img/6.jpg';
import slide7 from '../images/banner-img/7.jpg';
import slide8 from '../images/banner-img/8.jpg';
import slide9 from '../images/banner-img/9.jpg';
import slide10 from '../images/banner-img/10.jpg';
import slide11 from '../images/banner-img/11.jpg';
import slide12 from '../images/banner-img/12.jpg';
import home from './../coman/css/home.module.css';



function Banner() {
  return (
    <>
    <div className='slider'>
     
      <Carousel variant="dark" className={home.sliderSimages}>
       
        <Carousel.Item className='animate__animated animate__jackInTheBox'>
          <img src={slide1} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateInUpRight'>
          <img src={slide2}  alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide3} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide4} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide5} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide6} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide7} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide8} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide9} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide10} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide11} alt="banner"/>
        </Carousel.Item>
        <Carousel.Item className='animate__animated animate__rotateIn'>
          <img src={slide12} alt="banner"/>
        </Carousel.Item>
      </Carousel>
      </div>
    </>
  );
}

export default Banner;
