import React  from 'react';
import Banner from "./Home/Banner";
import MoreData from "./Home/MoreData";
function Home() {
  return (
    <>
    <Banner/>
    <MoreData/>
    </>
  );
}

export default Home;
